import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FlexCard from "../components/flexcard";
import GiftCardBackground from "../components/giftcardbackground";
import settings from "../../settings";

// images
const topbanner = `${settings.IMAGES_BASE_URL}/v2/images/specialoffers/sale-banner.jpg`;
const JuneOfferImage = `${settings.IMAGES_BASE_URL}/v2/images/socialspecialoffer/desktop/bmw-m5-fathers-day.jpg`;

const SpecialOffersSocial = () => {
  const today = new Date();
  const juneDate =
    today >= new Date(2022,4,20) &&
    today < new Date(2022,5,22,5);
  return (
    <Layout>
      <SEO
        title="BMW Performance Driving School | Gift Cards"
        description="Frequently asked questions for new BMW owners who qualify for the BMW Performance Center Delivery Program and are ready to fully experience BMW culture!"
      />
      <div className="giftcardpage giftcardpagesocial">
        <GiftCardBackground
          imageDesktop={topbanner}
          imageMobile={topbanner}
          imageAlt="BMW Gift Card Top Banner"
        />

        <section className="performance-center">
          <div className="container">
            <div className="performance-center__header">
              <h2>Special Offers</h2>
            </div>
          </div>
          {juneDate && (<FlexCard
            rowType="row"
            image={JuneOfferImage}
            imageAlt="DRIFTING DADS"
            header="DRIFTING DADS"
            subheader="20% OFF A GIFT CARD"
            btnStyle="btn-blue"
            phone="Call 888-345-4269 to purchase"
            >
            <p>
              Dad love thrills? Give him a Father’s Day gift that will get his heart racing like no other with a Gift Card to the BMW Performance Center. Purchase on or before June 19, and you’ll get 20% off.
            </p>
            <p>
              To redeem this offer, call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> and use the promo code <span className="bold">22FBFDGC20</span>. Must purchase gift card by 6/19/22. This includes the Performance Center Drive, M Track Drive, New Owner’s Track Drive and Track Meet. All other programs are excluded.
            </p>
          </FlexCard>)}
        </section>
      </div>
    </Layout>
  );
};

export default SpecialOffersSocial;
